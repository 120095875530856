<template>
    <div>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-dialog fullscreen v-model="dialog" max-width="800px">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.landing.newItem') }}</v-btn>
                            </template>
                            <landing-form
                                v-if="dialogLoaded"
                                :itemId="editedItem"
                                v-on:saved="onSave"
                                v-on:updated="onUpdate"
                                v-on:close="close"
                            />
                        </v-dialog>

                        <delete-dialog
                            :dialogTrigger="deleteDialog"
                            :item-id="deleteItemId"
                            :delete-route="deleteRoute"
                            @closed="deleteDialogClosed"
                            @deleted="deleteDialogDeleted"
                        >
                            <template v-slot:title>
                                {{ trans('pages.landing.deleteDialogTitle') }}
                            </template>
                            <template v-slot:text>
                                {{ trans('pages.landing.deleteDialogText', {id: deleteItemText ? '"'+deleteItemText+'"' : deleteItemId }) }}
                            </template>
                        </delete-dialog>

                        <v-spacer></v-spacer>
                        <v-icon
                            class="pull-right"
                            color="primary"
                            @click="filterShow = filterShow === 0 ? null : 0"
                        >mdi-filter</v-icon>
                        <v-expansion-panels
                            v-model="filterShow"
                            flat
                            tile
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-content class="pt-4">
                                    <v-row>
                                        <v-col style="min-width: 200px;">
                                            <v-text-field
                                                v-model="filters.label"
                                                :label="trans('fields.common.label')"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col style="min-width: 200px;">
                                            <v-select
                                                :items="options.countries"
                                                :label="trans('fields.common.countries')"
                                                :multiple="true"
                                                v-model="filters.country_id"
                                            ></v-select>
                                        </v-col>
                                        <v-col style="min-width: 200px;">
                                            <v-select
                                                :items="options.languages"
                                                :label="trans('fields.common.languages')"
                                                :multiple="true"
                                                v-model="filters.language_id"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-btn
                                            class="ma-2"
                                            color="primary"
                                            outlined
                                            @click="applyFilters"
                                        >{{ trans('buttons.common.apply') }}</v-btn>
                                        <v-btn
                                            class="ma-2"
                                            color="orange"
                                            outlined
                                            @click="clearFilters"
                                        >{{ trans('buttons.common.clear') }}</v-btn>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="loading">
            <v-col cols="12" lg="6" xl="3">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" lg="6" xl="3" class="d-none d-lg-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" lg="6" xl="3" class="d-none d-xl-block">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else-if="!landings.data.length">
            <v-col>
                <v-container>
                    <v-card>
                        <v-card-text v-if="$store.getters.vendorAlias === 'workle'" v-html="trans('pages.dashboard.landingsListItemsWorkle')"></v-card-text>
                        <v-card-text v-else v-html="trans('pages.dashboard.landingsListItems')"></v-card-text>
                    </v-card>
                </v-container>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" lg="6" xl="4" v-for="item in landings.data" :key="item.id">
                <v-card>
                    <v-container fluid class="pt-0 pb-0">
                        <v-row justify="space-between">
                            <v-col cols="12" sm="5" class="pa-0" >
                                <v-skeleton-loader
                                    v-if="!item.design.imageUrl"
                                    width="100%"
                                    boilerplate
                                    type="image"
                                ></v-skeleton-loader>
                                <div
                                    v-else
                                    style="max-height: 480px; overflow: hidden;"
                                >
                                    <v-img
                                        max-width="100%"
                                        position="center top"
                                        :src="item.design.imageUrl"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>

                                </div>
                            </v-col>
                            <v-col
                                cols="12" sm="7" class="pt-0"
                            >
                                <v-row>
                                    <v-col class="pt-1">
                                        <v-chip label color="primary">id: {{item.id}}</v-chip>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pb-1">
                                        {{ item.label }}
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-1">
                                        <span class="text--disabled">{{ trans('fields.common.domain') }}: </span>
                                        <template v-if="item.domain">
                                            <a target="_blank" :href="'https://' + item.domain.name"><v-icon small color="primary">mdi mdi-open-in-new</v-icon> {{item.domain.name}}</a>
                                        </template>
                                        <template v-else>
                                            <span class="text--disabled">{{ trans('pages.landing.noDomain') }}</span>
                                        </template>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" class="pb-1">
                                        <h3 class="text--disabled font-weight-light">{{ trans('fields.common.offers') }}</h3>
                                    </v-col>
                                </v-row>
                                <v-row justify="space-between">
                                    <v-col style="min-width: 180px;" class="pt-1">
                                        <v-row>
                                            <v-col>
                                                {{ trans('pages.landing.offersCorrect') }}:
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-sheet
                                                    label
                                                    color="light-green"
                                                    dark
                                                    class="pull-right counter pa-1"
                                                >{{ item.offer_appearances_filled_count }}</v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col style="min-width: 180px;" class="pt-1">
                                        <v-row>
                                            <v-col>
                                                {{ trans('pages.landing.offersNeedConfig') }}:
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-sheet
                                                    :color="item.offer_appearances_filled_count < item.offer_appearances_count ? 'red darken-1' : 'light-green'"
                                                    class="pull-right counter pa-1"
                                                    dark
                                                >{{ item.offer_appearances_count - item.offer_appearances_filled_count }}</v-sheet>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pb-1">
                                        <span class="text--disabled">{{ trans('fields.common.language') }}:</span>
                                        <template v-if="options && options.languagesKeyed">
                                            {{ options.languagesKeyed[item.language_id] }}
                                        </template>
                                        <v-skeleton-loader
                                            v-else
                                            type="text"
                                            class="d-inline-block"
                                            max-width="100px"
                                            width="100px"
                                        ></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-1 pb-1">
                                        <span class="text--disabled">{{ trans('fields.common.country') }}:</span>
                                        <template v-if="options && options.countriesKeyed">
                                            {{ options.countriesKeyed[item.country_id] }}
                                        </template>
                                        <v-skeleton-loader
                                            v-else
                                            type="text"
                                            class="d-inline-block"
                                            max-width="100px"
                                            width="100px"
                                        ></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-1 pb-1">
                                        <span class="text--disabled">{{ trans('fields.common.design') }}:</span>
                                        {{ item.design.label }}
                                    </v-col>
                                </v-row>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <a
                                        :href="route('user.landings.preview', item.id)"
                                        :title="trans('buttons.common.preview')"
                                        target="_blank"
                                    ><v-icon class="mr-2" color="primary">mdi-eye</v-icon></a>
                                    <v-icon
                                        class="mr-2"
                                        :title="trans('buttons.common.edit')"
                                        color="primary"
                                        @click="editItem(item)"
                                    >mdi-pencil</v-icon>
                                    <v-icon
                                        :title="trans('buttons.common.delete')"
                                        color="primary"
                                        @click="deleteDialogShow(item.id, item.label)"
                                    >mdi-delete</v-icon>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="landings && landings.data && landings.data.length">
            <v-col cols="12">
                <v-pagination
                    v-model="paginationParams.page"
                    :length="pagination.pageStop"
                    :page="pagination.page"
                    :total-visible="12"
                    :disabled="loading"
                    @input="changePage"
                ></v-pagination>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">

            </v-col>
        </v-row>
    </div>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import tableFilters from "@/mixins/tableFilters";

    export default {
        name: "LandingTable",
        components: {
            LandingForm: () => import('@/components/user/landing/LandingForm'),
            DeleteDialog,
        },
        mixins: [
            tableWithPagination,
            deleteDialog,
            tableFilters
        ],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                landings: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: false },
                    { text: this.trans("fields.common.domain"), value: "domain.name", sortable: false },
                    { text: this.trans("fields.common.offers"), value: "offer_appearances_count", sortable: false },
                    { text: this.trans("fields.common.language"), value: "language", sortable: false },
                    { text: this.trans("fields.common.country"), value: "country", sortable: false },
                    { text: this.trans("fields.common.landingDesign"), value: "design.label", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('user.landings.index'),
                deleteRoute: 'user.landings.delete',

                editedItem: null,
            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({languages: true, countries: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.initialize();
            },
            onUpdate(){
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                    this.initialize();
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.landings;
                },
                set(v){
                    this.landings = v;
                }
            },
        }
    }
</script>

<style scoped>
    .small-caption {
        font-size: 9px;
        margin-left: -6px;
    }
    .counter {
        width: 40px;
        text-align: center;
    }
</style>
